import { DeviceKind, DeviceStatus } from '~/generated/graphql';
import { isSmDown } from '~/lib/responsive';
import type { DeviceFiltersState, useDeviceFiltersParams } from './';

export const INITIAL_ARCH = [
  { label: 'arm', selected: false, value: 'arm' as const },
  { label: 'arm64', selected: false, value: 'arm64' as const },
  { label: 'x64', selected: false, value: 'x64' as const },
];

export const INITIAL_DEMO = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

export const INITIAL_DISPLAY_ON = [
  { label: 'On', selected: false, value: true },
  { label: 'Off', selected: false, value: false },
];

export const INITIAL_INTERNAL = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

export const INITIAL_KIND = [
  { label: 'Android', selected: false, value: DeviceKind.Android },
  { label: 'Brightsign', selected: false, value: DeviceKind.Brightsign },
  { label: 'Linux', selected: false, value: DeviceKind.Linux },
  { label: 'Samsung', selected: false, value: DeviceKind.Samsung },
  { label: 'webOS', selected: false, value: DeviceKind.Webos },
];

export const INITIAL_SILENCE = [
  { label: 'True', selected: false, value: true },
  { label: 'False', selected: false, value: false },
];

export const INITIAL_STATUS = [
  { label: 'Online', selected: false, value: DeviceStatus.Healthy },
  { label: 'Offline', selected: false, value: DeviceStatus.Error },
  { label: 'Warning', selected: false, value: DeviceStatus.Warn },
];

export const allUnselectedState: DeviceFiltersState = {
  filters: {
    arch: { open: false, items: INITIAL_ARCH },
    demo: { open: false, items: INITIAL_DEMO },
    displayOn: { open: false, items: INITIAL_DISPLAY_ON },
    groups: { open: false, items: [] },
    internal: { open: false, items: INITIAL_INTERNAL },
    kind: { open: false, items: INITIAL_KIND },
    silence: { open: false, items: INITIAL_SILENCE },
    status: { open: false, items: INITIAL_STATUS },
    tags: { open: false, items: [] },
  },
  panel: { open: false },
};

export const useDeviceFiltersInitialState = (params: ReturnType<typeof useDeviceFiltersParams>) => {
  const isDeviceSmDown = isSmDown();
  return {
    filters: {
      arch: {
        open: params.arch.length > 0,
        items: INITIAL_ARCH.map((x) => ({ ...x, selected: params.arch.includes(x.value) })),
      },
      demo: {
        open: params.demo.length > 0,
        items: INITIAL_DEMO.map((x) => ({ ...x, selected: params.demo.includes(x.value) })),
      },
      displayOn: {
        open: params.displayOn.length > 0,
        items: INITIAL_DISPLAY_ON.map((x) => ({
          ...x,
          selected: params.displayOn.includes(x.value),
        })),
      },
      groups: { open: false, items: [] },
      internal: {
        open: params.internal.length > 0,
        items: INITIAL_INTERNAL.map((x) => ({
          ...x,
          selected: params.internal.includes(x.value),
        })),
      },
      kind: {
        open: params.kind.length > 0,
        items: INITIAL_KIND.map((x) => ({ ...x, selected: params.kind.includes(x.value) })),
      },
      silence: {
        open: params.silence.length > 0,
        items: INITIAL_SILENCE.map((x) => ({ ...x, selected: params.silence.includes(x.value) })),
      },
      status: {
        open: params.status.length > 0,
        items: INITIAL_STATUS.map((x) => ({ ...x, selected: params.status.includes(x.value) })),
      },
      tags: { open: false, items: [] },
    },
    panel: { open: !isDeviceSmDown && Object.values(params).flat().length > 0 },
  };
};
